<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else>
		<form class="main-wrapper mt-4">
			<CRow class="mb-5">
				<CCol md="12">
					<div class="typo-h4">
						Export
					</div>
					<div class="typo-body-2 color-black-45">
						Set start and end date for back in stock notification to export SKUs and customer name.
					</div>
				</CCol>
				<CCol md="6" class="mt-2">
					<BaseInputDate
						v-model="$v.filterForm.localStartDate.$model"
						:is-valid="!$v.filterForm.localStartDate.$error"
						:invalid-feedback="$t('global.error.required')"
						label="Start date*"
						placeholder="Select date"
						data-test-id="start-date"
						name="start-date"
						@input="handleFilterStartDateChange"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
				</CCol>
				<CCol md="6" class="mt-2">
					<BaseInputDate
						v-model="filterForm.localEndDate"
						:min-date="filterForm.localStartDate"
						:disabled="!filterForm.localStartDate"
						label="End date"
						placeholder="Select date"
						data-test-id="end-date"
						name="end-date"
						@input="handleFilterEndDateChange"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
				</CCol>
				<CCol md="12" class="mt-4">
					<div class="typo-body-2 color-black-70">{{ meta.total }} item(s) found</div>
				</CCol>
				<CCol md="12" class="mt-4">
					<CButton
						:disabled="isExporting || $v.$invalid"
						class="btn-export"
						color="secondary"
						@click="exportBackInStockNotification"
					>
						{{ isExporting ? "Exporting" : "Export .xlsx" }}
					</CButton>
				</CCol>
			</CRow>
		</form>

		<BaseModalConfirm
			ref="modal-export-back-in-stock-notification"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportBackInStockNotification"
		/>
	</div>
</template>
<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import BaseInputDate from '@/components/BaseInputDate.vue';

import { exportBackInStockInfoAPI } from '../services/api/export.api';
import { cleanObject, cloneDeep, hasSearchFilter, convertDateTimeToUTC, datetimeFormat } from '../assets/js/helpers';

export default {
	name: 'BackInStockNotification',

	components: {
		BaseModalConfirm,
		BaseInputDate,
	},

	validations() {
		return {
			filterForm: {
				localStartDate: {
					required,
				},
			},
		};
	},

	data() {
		return {
			// filter form
			filterForm: {
				localStartDate: null,
				localEndDate: null,
			},
			// query params from url
			queryParams: {
				start_date: this.$route.query.start_date || null,
				end_date: this.$route.query.end_date || null,
			},
			isExporting: false,
			isShowFilter: false,
		};
	},

	computed: {
		...mapState('backInStock', {
			list: 'list',
		}),

		isLoading() {
			return this.list.isLoading;
		},

		meta() {
			return this.list.meta;
		},

		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},

		dateStart() {
			if (!this.queryParams.start_date) {
				this.resetFilter();

				return null;
			}

			return new Date(this.queryParams.start_date);
		},

		dateEnd() {
			if (!this.queryParams.end_date) {
				return null;
			}

			return new Date(this.queryParams.end_date);
		},
	},

	async created() {
		if (this.hasFilter) {
			this.filterForm.localStartDate = this.dateStart;
			this.filterForm.localEndDate = this.dateEnd;
		}

		await this.fetchData();
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getBackInStockList: 'backInStock/getBackInStockList',
		}),

		fetchData() {
			// API back-in-stock-notification require start_data
			if (this.queryParams.start_date) {
				this.getBackInStockList(this.queryParams);
			}
		},

		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},

		handleFilterStartDateChange(event) {
			const value = event ? convertDateTimeToUTC(event) : null;

			if (!value) {
				this.queryParams.start_date = value;
				this.updateUrlParams();
				return;
			}

			// If the period start date is after the period end date, re-initail the period end date
			if (this.filterForm.localEndDate && dayjs(event).isAfter(dayjs(this.filterForm.localEndDate))) {
				this.queryParams.end_date = datetimeFormat(value, 'YYYY-MM-DD');
			}

			this.queryParams.start_date = datetimeFormat(value, 'YYYY-MM-DD');
			this.updateUrlParams();
		},

		handleFilterEndDateChange(event) {
			const value = event ? convertDateTimeToUTC(event) : null;

			if (!value) {
				this.queryParams.end_date = value;
				this.updateUrlParams();
				return;
			}

			this.queryParams.end_date = datetimeFormat(value, 'YYYY-MM-DD');
			this.updateUrlParams();
		},

		resetFilter() {
			this.queryParams = {
				start_date: null,
				end_date: null,
			};

			this.updateUrlParams();
		},

		exportBackInStockNotification() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			this.$refs['modal-export-back-in-stock-notification'].open();
		},

		async handleExportBackInStockNotification() {
			this.isExporting = true;

			try {
				await exportBackInStockInfoAPI({
					start_date: this.queryParams.start_date,
					end_date: this.queryParams.end_date,
				});

				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.resetFilter();

				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.$refs['modal-export-back-in-stock-notification'].close();
				this.isExporting = false;
			}
		},
	},
};
</script>

